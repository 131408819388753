import React from "react"
import { graphql} from 'gatsby'
import Layout from "../components/layout"
import Seo from "../components/seo"
import {Top, TopImg, Title, InfoBlock, Info, InfoTitle, Dot, Content, ImageBlock, Para, ContentImg} from "../styles/marineStyles"
import MarineTopImg from "../images/marineTop.png"
import BackImg from "../images/back.png"
import ContentBarging from "../images/barging2.png"
import ContactForm from "../components/contact"

export default class Pile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false
    }
  }
  
render() {
  return (
      <Layout>
        <Seo 
          title="Pile Driving Services" 
          description="Pile driving is one of the most reliable and long lasting options for dock foundations in Northern Idaho. At Jakes on the Lakes we have a wealth of knowledge and experience."
          keywords={["Jake On The Lakes", "Pile Driving Services", "Coeur d’Alene Lake", "Hauser Lake", "Newman Lake", "Priest Lake", "Pend Oreille Lake", "Cocolalla Lake", "Spokane River", "Northern Idaho", "Expertise In The Northwest", process.env.DRUPAL_WEBFORM_ENDPOINT, process.env.GATSBY_PHONE_NUMBER]}
        />
        <Top>
          <Title>PILE DRIVING SERVICES</Title>
          <div><Dot/><Dot/><Dot/></div>
          <TopImg style={{backgroundImage: `url(${MarineTopImg})`}}></TopImg>
        </Top>
        <InfoBlock style={{backgroundImage: `url(${BackImg})`}}>
          <Info>
            <InfoTitle>EXPERTISE IN THE NORTHWEST</InfoTitle>
            <div><Dot/><Dot/><Dot/></div>
            <p style={{fontSize: "18px"}}>Pile driven docks are one of the best options for your lakeside needs. Pile docks are sturdy, durable and withstand the test of time and abuse. Pile driving is the process of driving piles deep into the lake bottom to secure and stabilize the dock or structure built on top of it. High impact hammers, called drop hammers, are used by our workers to drive the piles deep into the lake bottom. Deep foundations keep your dock stable and secure. Pile driving is one of the most reliable and long lasting options for dock foundations in Northern Idaho.</p>
          </Info>
        </InfoBlock>
        <Content>
          <Para>Some of the best materials for piles are wood and steel. Here at Jakes on the Lakes we have both. We use tamarack wood, which is highly durable and rot resistant. When looking for a slightly cheaper option, tamarack might be right for you. Here at Jakes on the Lakes, we often recommend our steel piles. Steel piles offer the greatest load capacity and corrosion resistance. We use steel pipe pilings which can handle extremely heavy loads. Where wood pilings can after a while begin to wear down just from the elements and dock connectors, steel pilings will maintain rigidity and durability despite what the weather or general wear and tear can throw at them.<br/><br/>There are many options for pile driven docks. One of the most common docks, pier docks, are built right onto the pilings. Other pile docks are held together with hoops and chains. Jake on the Lakes provides both types as options. Pile driving is one of the most reliable and long lasting options for dock foundations in Northern Idaho. At Jakes on the Lakes we have a wealth of knowledge and experience. There is great demand for piling in the area but few companies that provide the service. Whether you're on Lake Coeur d’Alene, Hauser Lake, Newman Lake, Priest Lake, Lake Pend Oreille, Lake Cocolalla, Liberty Lake or Spokane River, Jakes on the Lakes is here for you.</Para>
          <ImageBlock>
            <ContentImg src={ContentBarging} alt="Barging in Action"/>
          </ImageBlock>
        </Content>
        <ContactForm data={this.props.data} />
      </Layout>
    )
  }
}

export const query = graphql`
query {
  webformWebform(drupal_internal__id: { eq: "9gk5ucvtcrek9dgqtqq28a8fkvvhkdb2" }) {
    drupal_internal__id
    elements {
        name
        type
        attributes {
          name
          value
        }
    }
  }
}
`